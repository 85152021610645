import React from "react"
import styles from "../../styles/popout.module.scss"

const Popout = ({ children, colors }) => {
    return (
        <div className={styles.main}>
            <div className={styles.inner}>
                <div
                    className={styles.bottom}
                    style={{ backgroundColor: colors[0] }}
                ></div>
                <div
                    className={styles.middle}
                    style={{ backgroundColor: colors[1] }}
                ></div>
                <div className={styles.top}>{children}</div>
            </div>
        </div>
    )
}

export default Popout
