import React from "react"
import { Link } from "gatsby"
import Image from "@/components/general/Image"
// import Layout from "@/components/layout"
// import Image from "@/components/image"
import SEO from "@/components/seo"
import Opener from "@/components/general/Opener"
import Sizing from "@/components/general/Sizing"
import Parallax from "@/components/general/Parallax"
import Heading from "@/components/general/Heading"
import BoredomGraph from "@/components/graphs/BoredomGraph"
import ColorChangeBG from "@/components/general/ColorChangeBG"
import Spacing from "@/components/general/Spacing"
import Signup from "@/components/general/Signup.es"
import Dropcap from "@/components/general/Dropcap"
import FooterNav from "@/components/general/FooterNav"
import Popout from "@/components/general/Popout"
import Motif from "@/components/general/Motif"
// import pullquote from "@/images/boredom/pullquote.svg"
import Footer from "@/components/general/Footer.es"
import Share from "@/components/general/Share.es"

import openerText from "@/images/climate/opener-text_spanish.png"
import openerTextSml from "@/images/climate/opener-text-sml_spanish.png"

import dcm from "@/images/climate/dcm_spanish.png"
import dcw from "@/images/climate/dcw_spanish.png"
import dca from "@/images/climate/dca_spanish.png"
import dct from "@/images/climate/dct_spanish.png"
import shareImg from "@/images/og/og_climate_spanish.jpg"

import SignupPopup from "@/components/general/SignupPopup.es"

const ClimatePage = ({pageContext}) => {
    return (
        <>
            <SEO
                page="/es/climate-change"
                title="Free Markets Destroy | Aprenda cómo los mercados libres destruyen el cambio climático"
                description="Tenemos que tomar en serio la amenaza del cambio climático. Todos los días nos enfrentamos a noticias que parecen más condenatorias para el planeta. Pero si cree que la situación es desesperada, piénselo de nuevo."
                img={shareImg}
                lang="es"
            />

            <Opener
                pageName="climate"
                titles={[
                    { img: openerText, size: 14 },
                    { img: openerTextSml, size: 22 },
                ]}
            ></Opener>

            {
                // ======================================= SECTION 1 =======================================
            }

            <ColorChangeBG
                from={{ r: 255, g: 255, b: 255 }}
                to={{ r: 104, g: 197, b: 184 }}
            >
                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                display: "none",
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 6,
                                width: 3,
                                marginTop: -4,
                            },
                        },
                    ]}
                >
                    <Motif page="climate" id="motif_3" movement={0.1} />
                </Sizing>
                <Sizing
                    styles={{ float: "right" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginRight: 2,
                                width: 20,
                                marginBottom: 3,
                                // marginTop: 2,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginRight: 2,
                                width: 10,
                                marginBottom: 1,
                            },
                        },
                        { min: 1200, val: { marginLeft: 8, width: 10 } },
                    ]}
                >
                    <div className={"copy--lead"}>
                        <Dropcap img={dcw}>T</Dropcap>enemos que tomar en serio la amenaza del cambio climático. Si bien el planeta en sí es notablemente robusto—ha sobrevivido innumerables glaciaciones, erupciones volcánicas, inundaciones y sequías—los seres humanos somos frágiles. Necesitamos agua dulce, tierra cultivable y biodiversidad para sobrevivir. Cada día nos enfrentamos a las noticias que parecen más condenatorias para el planeta y para nuestro futuro.

                    </div>
                </Sizing>

                <Sizing
                    styles={{ float: "left", position: "relative" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 1,
                                width: 22,
                                paddingBottom: 0.3,
                                marginTop: 1,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginTop: 0,
                                marginLeft: 1,
                                width: 16,
                                paddingBottom: 0.3,
                                // marginBottom: 1,
                            },
                        },
                    ]}
                >
                    <Image src="climate_flood" />

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    top: 3,
                                    width: 2,
                                    right: -6,
                                },
                            },
                        ]}
                    >
                        <Motif page="climate" id="cover_6" movement={0.1} />
                    </Sizing>
                </Sizing>

                <div style={{ float: "left", clear: "left" }}>
                    <SignupPopup></SignupPopup>
                </div>

                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 3,
                                width: 20,

                                marginTop: 0.5,
                                marginBottom: 1,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 3,
                                width: 16,
                                marginTop: 0,
                                marginBottom: 0,
                                // marginBottom: 1,
                            },
                        },
                    ]}
                >
                    <div className="caption">
                        - Inundaciones destructivas en el sureste de EE. UU.
                    </div>
                </Sizing>

                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 2,
                                width: 20,
                                marginTop: 1,
                                // marginTop: 2,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginTop: 1,
                                marginLeft: 8,
                                width: 8,
                            },
                        },
                    ]}
                >
                    <div style={{ position: "relative" }}>
                        <Sizing
                            styles={{ position: "absolute" }}
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        top: -2,
                                        width: 3,
                                        right: -6,
                                    },
                                },
                            ]}
                        >
                            <Motif
                                page="climate"
                                id="cover_2"
                                movement={-0.1}
                            />
                        </Sizing>

                        <p className="copy">
                            Pero si crees que la situación es desesperada, piénsalo de nuevo. En los últimos tres siglos, los mercados libres han canalizado la creatividad humana hacia soluciones a enormes desafíos como el hambre, la pobreza y la enfermedad. Los mercados crearon nuestra sociedad mundial moderna, con economías interdependientes que fomentan la paz y la tolerancia. Si la humanidad va a defenderse del cambio climático, entonces serán los mercados libres los que lo hagan realidad.
                        </p>
                    </div>
                    <div style={{ position: "relative" }}>
                        <Sizing
                            styles={{ position: "absolute" }}
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        bottom: 0,
                                        width: 2,
                                        left: -6,
                                    },
                                },
                            ]}
                        >
                            <Motif
                                page="climate"
                                id="cover_6"
                                movement={-0.05}
                            />
                        </Sizing>

                        <p className="copy">
                            Antes de adentrarnos en algunas de las formas en que los mercados libres ya están abordando el cambio climático, vale la pena aclarar algunos conceptos erróneos. Desde la década de 1980, las mayores reducciones en las emisiones de gases de efecto invernadero han provenido de las economías de libre mercado. Los datos muestran que cuando los mercados están limitados, el medio ambiente se ve afectado.
                        </p>
                    </div>
                    <div style={{ position: "relative" }}>
                        <Sizing
                            styles={{ position: "absolute" }}
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        top: 2,
                                        width: 1.5,
                                        left: -3.5,
                                    },
                                },
                            ]}
                        >
                            <Motif page="climate" id="cover_5" movement={0.1} />
                        </Sizing>

                        <p className="copy">
                            Este fenómeno fue predicho hace décadas por el economista ganador del Premio Nobel Simon Kuznets. En la década de 1950, Kuznets planteó la hipótesis de que a medida que se desarrolla una economía, las fuerzas del libre mercado primero aumentan y luego disminuyen la desigualdad económica. Con el tiempo, a medida que la economía madura y el ingreso per cápita aumenta, la salud ambiental aumenta. ¿La razón? Cuando usted está luchando para alimentar a su familia, no puede permitirse el lujo de cuidar el medio ambiente. El aire y el agua limpios pueden ser esenciales para nuestra supervivencia, pero también son artículos de lujo para millones de personas. Lo que la llamada curva de Kuznets demuestra es que la mejor respuesta a la contaminación es la prosperidad económica.
                        </p>
                    </div>
                </Sizing>
            </ColorChangeBG>
            <ColorChangeBG
                from={{ r: 104, g: 197, b: 184 }}
                to={{ r: 209, g: 221, b: 38 }}
            >
                {/* <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                > */}

                <Sizing
                    styles={{ flexGrow: 0, flexShrink: 0 }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                display: "block",
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            },
                        },
                    ]}
                >
                    <Sizing
                        styles={{ flexGrow: 0, flexShrink: 0 }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    width: 20,
                                    float: "left",
                                    marginLeft: 2,
                                    marginBottom: 2,
                                    marginTop: 2,
                                },
                            },
                            {
                                min: 1024,
                                val: {
                                    width: "auto",
                                    float: "none",
                                    marginLeft: 0,
                                    marginBottom: 0,
                                    marginTop: 0,

                                    paddingLeft: 1,
                                    flexBasis: 10,
                                },
                            },
                        ]}
                    >
                        <Image src="climate_forest" />
                    </Sizing>
                    <Sizing
                        styles={{ flexGrow: 0, flexShrink: 0 }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    width: 20,
                                    float: "left",
                                    marginLeft: 2,
                                    marginBottom: 2,
                                },
                            },
                            {
                                min: 1024,
                                val: {
                                    width: "auto",
                                    float: "none",
                                    marginLeft: 0,
                                    marginBottom: 0,

                                    paddingLeft: 1,
                                    flexBasis: 8,
                                },
                            },
                        ]}
                    >
                        <div className={"copy"}>
                            <Dropcap img={dca}>O</Dropcap>
                            tra razón por la que los mercados libres tienden a producir entornos mas limpios son derechos de propiedad privada. En países donde los derechos de propiedad son sólidos, se incentiva a los propietarios de tierras a administrar sus recursos de manera responsable. Si alguna vez ha tenido su propio patio trasero, conoce la sensación. 
                        </div>

                        <p className="copy">
                            En Estados Unidos, el 56% de todas las tierras boscosas son de propiedad privada y, sin embargo, tenemos más tierras forestales que en el primer Día de la Tierra hace más de 40 años. Y a pesar de que el 91% de toda la madera extraída en los EE. UU. proviene de estos bosques privados, el stock total de madera en tierras privadas se ha duplicado desde 1953. Eso se debe a que los individuos, las familias y las empresas propietarias de la tierra tienen mucho en juego por lo tanto, quieren mantener los árboles creciendo y replantar lo que toman para seguir obteniendo valor de ellos, ya sea que ese valor sea financiero o puramente estético.
                        </p>
                    </Sizing>
                </Sizing>

                {
                    // ======================================= SECTION 2 =======================================
                }
                <Sizing
                    styles={{ float: "left", position: "relative" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 3,
                                marginBottom: 2,
                                marginTop: 2,
                                width: 12,
                            },
                        },
                    ]}
                >
                    <Heading langKey={pageContext.langKey}>SALVADO POR LOS BITS</Heading>

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    bottom: 2,
                                    width: 2,
                                    right: -4,
                                },
                            },
                        ]}
                    >
                        <Motif page="climate" id="cover_1" movement={0.05} />
                    </Sizing>

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    bottom: 0,
                                    width: 2,
                                    right: -5,
                                },
                            },
                        ]}
                    >
                        <Motif page="climate" id="cover_1" movement={0.15} />
                    </Sizing>
                </Sizing>

                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 20,
                                marginLeft: 2,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 4,
                                width: 8,
                            },
                        },
                        ,
                        {
                            min: 1600,
                            val: {
                                marginLeft: 6,
                                width: 8,
                            },
                        },
                    ]}
                >
                    <div className={"copy"}>
                        <Dropcap img={dct}>L</Dropcap>a afirmación central del libro More From Less, escrito por el economista del MIT Andrew McAfee, es que los mercados libres y la tecnología están ayudando a las naciones desarrolladas a utilizar menos recursos para producir niveles más altos de producción económica. En un nivel, esta "desmaterialización" es bastante sencilla: mientras que antes necesitabas una cámara, una calculadora, un diccionario, todos ellos hechos de materiales físicos como madera y plástico, ahora solo necesitas un iPhone. Un dispositivo reemplaza a docenas. Pero las fuerzas del mercado han alentado este proceso de desmaterialización en todos los niveles.
                    </div>
                    <p className={"copy"}>
                        Supongamos que eres dueño de una microcervecería. En 2020, es probable que las latas de aluminio que utiliza para envasar sus cervezas utilicen aproximadamente un 12% menos de metal que las latas de la década de 1980. ¿Por qué? Porque el modelado asistido por computadora permite a los ingenieros diseñar latas estructuralmente perfectas. Y, lo que es más importante, porque si decide no usar las latas más eficientes, su competidor en el futuro lo hará, y ella podrá bajar sus precios y robar algunos de sus clientes. La competencia y la innovación conspiran para salvar el planeta.
                    </p>
                </Sizing>
                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 18,
                                marginLeft: 2,
                                marginTop: 4,
                                marginBottom: 4,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 2,
                                width: 4,
                                minWidth: "350px",
                            },
                        },
                        {
                            min: 1200,
                            val: {
                                marginLeft: 3,
                                width: 4,
                                minWidth: "350px",
                            },
                        },
                    ]}
                >
                    <Signup color={"#E99245"}></Signup>
                </Sizing>

                {
                    // ======================================= END =======================================
                }
            </ColorChangeBG>
            <ColorChangeBG
                from={{ r: 209, g: 221, b: 38 }}
                to={{ r: 255, g: 255, b: 255 }}
            >
                <Sizing
                    styles={{ float: "left", position: "relative" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 1,
                                width: 22,
                            },
                        },
                    ]}
                >
                    <Image src={"climate_loggers"} />

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    bottom: -1.5,
                                    width: 0.8,
                                    right: 4,
                                },
                            },
                        ]}
                    >
                        <Motif page="climate" id="cover_4" movement={-0.15} />
                    </Sizing>
                </Sizing>
                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 18,
                                marginLeft: 2,
                                marginTop: -3,
                                // marginBottom: 1,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginTop: -3,
                                marginLeft: 3,
                                width: 11,
                            },
                        },
                    ]}
                >
                    <Popout colors={["#D1DD26", "#0B1837"]}>
                        <Sizing
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        padding: 1,
                                    },
                                },
                            ]}
                        >
                            <div className={"copy--lead"}>
                                En Estados Unidos, el 56% de todas las tierras boscosas son de propiedad privada y, sin embargo, tenemos más tierras forestales que en el primer Día de la Tierra hace más de 40 años. Y a pesar de que el 91% de toda la madera extraída en los EE. UU. proviene de estos bosques privados, el stock total de madera en tierras privadas se ha duplicado desde 1953.
                            </div>
                        </Sizing>
                    </Popout>
                </Sizing>

                <Sizing
                    styles={{ float: "left", clear: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                display: "none",
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                display: "block",
                                marginLeft: 2,
                                width: 0.8,
                                marginTop: 2,
                            },
                        },
                    ]}
                >
                    <Motif page="climate" id="cover_4" movement={-0.2} />
                </Sizing>

                <Sizing
                    styles={{ float: "right" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 20,
                                marginRight: 2,
                                marginTop: 2,
                                marginBottom: 2,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginTop: 2,
                                marginRight: 3,
                                width: 9,
                            },
                        },
                    ]}
                >
                    <p className={"copy"}>
                        McAfee no es escéptico del cambio climático. Su mantra es, "se está calentando; Somos nosotros; es malo; y podemos solucionarlo ". Pero es un optimista que sostiene que las tendencias en el mundo desarrollado deberían darnos la esperanza de un futuro más brillante y limpio. McAfee confía en que lo que él llama los Cuatro jinetes del optimismo (tecnología, capitalismo, conciencia pública y gobierno receptivo) continuará desatando el poder de la creatividad humana, creando una economía digital de abundancia. La gente será más rica, el planeta será más verde, pero solo si dejamos que los mercados libres lideren el camino.

                    </p>
                </Sizing>
            </ColorChangeBG>
            <ColorChangeBG
                from={{ r: 255, g: 255, b: 255 }}
                to={{ r: 57, g: 171, b: 221 }}
            >
                <Sizing
                    styles={{ float: "left", position: "relative" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 20,
                                marginLeft: 2,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                width: 5,
                                marginLeft: 3,
                            },
                        },
                    ]}
                >
                    <Heading navy={true} circle={false}>
                        LOS MERCADOS REQUIEREN ÉXITO
                    </Heading>

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    right: 0,
                                    width: 0.8,
                                    top: -3,
                                },
                            },
                        ]}
                    >
                        <Motif page="climate" id="cover_4" movement={-0.1} />
                    </Sizing>
                </Sizing>

                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 20,
                                marginLeft: 2,
                                marginTop: 3,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                width: 8,
                                marginLeft: 2,
                                marginTop: 3,
                            },
                        },
                    ]}
                >
                    <div className={"copy"} style={{ position: "relative" }}>
                        <Sizing
                            styles={{ position: "absolute" }}
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        right: -5,
                                        width: 3,
                                        top: -3,
                                    },
                                },
                            ]}
                        >
                            <Motif
                                page="climate"
                                id="cover_3"
                                movement={-0.1}
                            />
                        </Sizing>
                        <Dropcap img={dcm}>L</Dropcap>o que es más importante para el futuro, los mercados están probados. Han hecho más para reducir las emisiones de CO2 que los políticos y el gobierno. En todo Estados Unidos, ciudades como Seattle, Nueva York y Chicago prometieron reducir las emisiones, pero no lograron sus propios objetivos. Compare eso con Microsoft, que se compromete no solo a ser neutral en carbono, sino a eliminar el CO2 equivalente a sus emisiones desde que comenzaron en 1975. Walmart se compromete a reducir una gigatonelada de emisiones de CO2, más que las emisiones totales de CO2 de Francia durante tres años.
                    </div>

                    <div style={{ position: "relative" }}>
                        <Sizing
                            styles={{ position: "absolute" }}
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        left: -5,
                                        width: 3,
                                        bottom: -3,
                                    },
                                },
                            ]}
                        >
                            <Motif
                                page="climate"
                                id="cover_3"
                                movement={-0.2}
                            />
                        </Sizing>

                        <p className="copy">
                            Los mercados recompensan a las personas por la conservación. Compra un Prius y no solo estás ayudando al planeta, también estás ahorrando dinero. Compre un termostato inteligente, puede mantener su casa cómoda con menos electricidad. Los mercados responsabilizan a las personas y las empresas del éxito medioambiental. Los mismos políticos que no lograron cumplir con los últimos objetivos de CO2, por otro lado, prometen que la próxima vez será diferente. El cambio climático es demasiado importante para confiar en los políticos que pueden estar fuera de sus funciones antes de que la sociedad pueda medir su éxito.
                        </p>
                    </div>
                </Sizing>

                <Sizing
                    styles={{ float: "left", position: "relative" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 22,
                                marginLeft: 1,
                                marginBottom: 3,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                margin: 3,
                                width: 18,
                                marginBottom: 0,
                            },
                        },
                    ]}
                >
                    <Image src={"climate_tech"} />

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    right: -1,
                                    width: 1,
                                    top: -1,
                                },
                            },
                        ]}
                    >
                        <Motif page="disease" id="motif_1" movement={0.1} />
                    </Sizing>
                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    right: -1,
                                    width: 0.6,
                                    bottom: 1,
                                },
                            },
                        ]}
                    >
                        <Motif page="disease" id="motif_2" movement={-0.1} />
                    </Sizing>
                </Sizing>
                <Spacing space="0" />
                <Sizing
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: "auto",
                                marginRight: "auto",
                                width: 20,
                                maxWidth: "300px",
                                height: "50px",
                                marginTop: 2,
                                paddingBottom: 5,
                                position: "relative",
                                display: "block",
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                display: "none",
                            },
                        },
                    ]}
                >
                    <Share></Share>
                </Sizing>
            </ColorChangeBG>
            {
                // ======================================= END =======================================
            }

            {/* <Spacing space="300vh" /> */}

            <Spacing space="0" />
            <FooterNav next={"boredom"} previous={"rents"} langKey={pageContext.langKey}/>
            <Footer></Footer>
        </>
    )
}

export default ClimatePage
